import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./Salesman.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { add, addcstName, addcstPlace } from "../../store/CustOrder";
import GlobalVariable from "../../GlobalVariable";
import { env } from "../../env";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import DashboardHeader from "../../snippets/Dashboard/DashboardHeader";
import SalesmanReport from "./components/SalesmanReport";
import SalesmanPendingBills from "./components/SalesmanPendingBills";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import ApiHandler from "../../ApiHandler";

export const UserContext = React.createContext();

export default function SalesmanDashboard() {
  //  const [deletes, setDeletes] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [showData, setShowData] = useState(false);
  const [data, setData] = useState(1);
  const [cstName, setCstName] = useState("");
  const [cstPlace, setCstPlace] = useState("");
  // const [hideData, setHideData] = useState([]);
  const [value, setValue] = useState("");
  const [allorderData, setAllOrderData] = useState([]);
  const [userName, setUserName] = useState("");
  const [showReports, setShowReports] = useState(false);
  const [showPendingBills, setShowPendingBills] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const typeaheadRef = useRef();

  const setShowPendingBillsHandler = () =>
    setShowPendingBills(!showPendingBills);

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user-info"));
  const baseLink = env.REACT_APP_BASE_URL
    ? env.REACT_APP_BASE_URL
    : "https://popsv3.azurewebsites.net/";

  function searchCustomerName(e) {
    //  setLoading(true);
    console.log(user);
    axios
      .post(
        baseLink + "/api/Search/CustomerByName/",
        {
          wClientid: GlobalVariable.wcClient,
          search: e.target.value,
          areaCode: user.areaCode + "" + 0,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((result) => {
        console.log(result);
        setUserName(result);
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);

          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
      })
      .finally(() => {
        // setLoading(false);
      });

    setValue(e.target.value);
  }

  useEffect(() => {
    typeaheadRef?.current?.focus();
    ApiHandler.get({ url: "api/OwnAppConfigs/" + GlobalVariable.wcClient }) //GlobalVariable.wcClient
      .then((res) => {
        localStorage.setItem("localUrlSales", res.localUrl);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {};
  }, []);

  function deletehandlerr(i) {
    const newData = allorderData.filter((l) => l.id !== i);
    setAllOrderData(newData);
  }

  function logout() {
    window.localStorage.removeItem("user-info");
    //history.push('/Customer');
    window.location.href = "/SalesMan";
  }

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query) => {
    setIsLoading(true);
    /////
    axios
      .post(
        baseLink + "/api/Search/CustomerByName/",
        {
          wClientid: GlobalVariable.wcClient,
          search: query,
          areaCode: user.areaCode + "" + 0,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((result) => {
        console.log(result);
        setCustomer(result.data);
        setOptions(result.data);
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);

          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    /////
  };
  const filterBy = () => true;
  console.log(showReports);
  return (
    <>
      <div className="customer_header">
        <DashboardHeader />
      </div>
      <br />
      <div className="row">
        <div className="offset-xl-2 col-xl-8 ">
          <p>Hello!{user.name}</p>
          {!showReports && (
            <button
              type="button"
              className="reportBTN"
              onClick={() => {
                setShowReports(true);
              }}
            >
              Reports
            </button>
          )}
          {showReports && (
            <button
              type="button"
              className="reportBTN"
              onClick={() => {
                setShowReports(false);
              }}
            >
              Back
            </button>
          )}
          <button onClick={logout} className="backBTN ml-3">
            Log Out
          </button>
        </div>
      </div>
      <br />
      {!showReports ? (
        <div>
          <div className="searchBar ">
            <div className="row">
              <div className="offset-xl-2 col-xl-8 ">
                <AsyncTypeahead
                  filterBy={filterBy}
                  id="async-example"
                  isLoading={isLoading}
                  labelKey="custName"
                  minLength={1}
                  ref={typeaheadRef}
                  onSearch={handleSearch}
                  selected={selectedCustomer ? selectedCustomer : []}
                  onChange={(value) => {
                    localStorage.removeItem("customerInCompany");
                    setSelectedCustomer(value);
                    if (value.length === 0) {
                      return;
                    }
                    const elm = value[0];
                    localStorage.setItem(
                      "customerInCompany",
                      JSON.stringify(elm)
                    );

                    const val = elm.custCode;
                    const cName = elm.custName;
                    const places = elm.place;
                    console.log({ elm });
                    dispatch(add(val));
                    dispatch(addcstName(cName));
                    dispatch(addcstPlace(places));
                    // setCustmerCode(elm.custCode) +
                    setCstName(elm.custName);
                    setCstPlace(elm.place);
                    setShowData(true);
                    setData(1);
                    setShowPendingBills(false);
                  }}
                  options={options ? options : []}
                  placeholder="Search for Customer"
                  renderMenuItemChildren={(option, i) => (
                    <React.Fragment key={i}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Customer: </span>
                        {option.custName}
                      </div>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Place: </span>
                        {option.place}
                      </div>
                    </React.Fragment>
                  )}
                />{" "}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-xl-2 col-xl-8 ">
              {showData
                ? [...Array(data)].map((index, i) => {
                    return (
                      <div className="mt-4 mb-2 row" key={i}>
                        <div
                          className=" col-xl-8   "
                          style={{ display: "flex", flexDirection: "column" }}
                          key={i}
                        >
                          <p>
                            <span style={{ fontWeight: "600" }} cdata={cstName}>
                              Customer Name :
                            </span>
                            {cstName}
                          </p>
                          <p>
                            <span style={{ fontWeight: "600" }}>Place : </span>{" "}
                            {cstPlace}
                          </p>
                        </div>

                        <div
                          className=" col-xl-4 col-lg-6  text-end"
                          style={{}}
                        >
                          <button
                            // className="DltBtn ml-2"
                            className="btn btn-sm btn-primary m-2 px-3 "
                            onClick={() => {
                              typeaheadRef?.current?.focus();
                              setData(data - 1);
                              setSelectedCustomer([]);
                            }}
                          >
                            <FontAwesomeIcon icon={faX} />
                          </button>

                          <Link to="/components/salesman/SmOrder">
                            <button
                              className="btn btn-sm btn-success m-2 px-3"
                              // className="OrdrBtn ml-2"
                            >
                              Order
                            </button>
                          </Link>

                          <button
                            // className="BillBtn ml-2"
                            className="btn btn-sm btn-danger m-2 px-3"
                            onClick={setShowPendingBillsHandler}
                          >
                            Pending Bills
                          </button>
                        </div>

                        {showPendingBills && <SalesmanPendingBills />}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      ) : (
        <>
          <SalesmanReport />
        </>
      )}
      <div>
        <UserContext.Provider value={cstName} />
      </div>
    </>
  );
}
