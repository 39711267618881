import { configureStore } from "@reduxjs/toolkit";

import UserReducer from "./UserOrders";
import userSlice from "../../../store/user-slice.js";
import CustOrder from "../../../store/CustOrder.js";

const UserProductstore = configureStore({
  reducer: {
    userOrders: UserReducer,
    userSlice: userSlice.reducer,
    order: CustOrder,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default UserProductstore;
