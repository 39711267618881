import React, { useState } from "react";
import Slider from "./Slider";
import MarqueeLComList from "./MarqueeLComList";
import IconTeam from "../../../assets/images/Icon_team.png";
// import { BackTop } from "antd";
import "../../../App.scss";

import img1 from "../../../Images/slider/1.JPG";
import img2 from "../../../Images/slider/2.JPG";
import img3 from "../../../Images/slider/3.JPG";
import img4 from "../../../Images/slider/4.JPG";
import img5 from "../../../Images/slider/5.JPG";
import img6 from "../../../Images/slider/6.JPG";
import img7 from "../../../Images/slider/7.JPG";
import img8 from "../../../Images/slider/8.JPG";
import img9 from "../../../Images/slider/9.JPG";
import img10 from "../../../Images/slider/10.JPG";
import img11 from "../../../Images/slider/11.JPG";
import img12 from "../../../Images/slider/12.JPG";
import img13 from "../../../Images/slider/13.JPG";
import InfoSlider from "./InfoSlider";

const HomeComponent = () => {
  const [allImages, setAllImages] = useState([
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
  ]);

  const infoSliderData = [
    {
      heading: ["Partner's Description"],
      list: [
        "1) D.S Guddodgi",
        "2) Sharanbasappa D Guddodgi",
        "3) Sangamesh D Guddodgi",
      ],
      paragraph: [
        "Presently we have 1200 total divisions of more than 500 companies catering the needs of retail chemist brothers mainly in Vijayapura, Bagalkot and Belgaum districts.",
        "We find our chemist brothers as representatives of GOD in getting the blessings from them.",
        "We always prepared to make availability of any required medicines of company marketing in Karnataka as per the requirements of the chemist brothers.",
      ],
    },
  ];

  return (
    <div className="home-component w-100 p-0">
      <div className="row w-100 no-background  ml-2">
        <div className="col-md-8 ">
          <Slider allImages={allImages} height={500} />
        </div>

        <div className="col-md-4 px-5 pt-4">
          <MarqueeLComList />
        </div>
      </div>

      <div className="company-info text-center w-100 mt-2  ">
        <h2>WELCOME TO GPD WEBSITE</h2>
        <img className="team-icon" src={IconTeam} alt="line" />
        <h2>Established On 31-12-1983</h2>

        <div className="info-carousel container mt-5">
          <InfoSlider sliderData={infoSliderData} />
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
