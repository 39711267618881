import React, { useEffect, useState } from 'react'
import ApiHandler from '../../../ApiHandler';
import { useSelector } from 'react-redux';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';



const initialValues = {
    from: dayjs(),
    to: dayjs()
};

const SalesmanPendingBills = (props) => {
    const [dates, setDates] = useState(initialValues);
    const { } = props;
    const custcodes = useSelector(c => c.order.custcodes);
    console.log({ custcodes });
    const [pendingBillLists, setPendingBillLists] = useState([]);

    useEffect(() => {
        CallPendingBills();
        return () => { }
    }, [dates.from, dates.to, custcodes])

    function CallPendingBills() {
        const url = localStorage.getItem("localUrlSales");
        ApiHandler.post({
            url: url + "api/OwnApp/GetPendingBill",
            data: {
                startdate: dayjs(dates.from).format("YYYYMMDD"),
                enddate: dayjs(dates.to).format("YYYYMMDD"),
                code: custcodes
            },
            useUserUrl: true
        })
            .then(res => {
                setPendingBillLists(res.data);
            }).catch(err => {
                console.log(err);
            });
    }


    return (
        <div>
            <div className="row ">
                <div className="col-lg-3  ">
                    <DatePicker.RangePicker
                        size="middle"
                        className=" mb-0 mr-2"
                        value={[dates.from, dates.to]}
                        format="DD-MM-YYYY"
                        onChange={(e) => {
                            console.log(e);

                            if (e) {
                                setDates({
                                    from: e[0],
                                    to: e[1],
                                });
                            } else {
                                setDates({ from: null, to: null });
                            }
                        }}
                    />
                </div>
                {/* <div className="col-lg-2  ">
                    <Button
                        type="button"
                        className="btn   btn-primary btn-sm"
                        onClick={toViewData}
                    >View</Button>

                </div> */}
            </div>

            <table className='table' border={1}>
                <thead>
                    <tr>
                        {/* _TrnType */}
                        <th>BillDate</th>
                        <th>BillNo</th>
                        <th>Amount</th>
                        <th>Pending</th>
                        <th>Running Total</th>
                        <th>PDC Amt</th>
                        <th>B.DisAmt</th>
                        <th>Days</th>
                        <th>Due Date</th>
                        {/* _Color */}
                    </tr>
                </thead>
                <tbody>
                    {pendingBillLists.map((elm, i) => {
                        return <tr key={i} className={elm._Color === "Y" ? "table-danger" : ''}>
                            <td>{elm.billDate}</td>
                            <td>{elm.billNo}</td>
                            <td>{elm.amount}</td>
                            <td>{elm.pending}</td>
                            <td>{elm.runningTotal}</td>
                            <td>{elm.pdcAmt}</td>
                            <td>{elm.bDisAmt}</td>
                            <td>{elm.days}</td>
                            <td>{elm.duedate}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default SalesmanPendingBills