import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import img2 from "../Images/logo.png";
import "../Styles/header.scss";
import { Company } from "../assets/Data";

function Header({ currentComponent, handleNavLinkClick }) {
  const history = useHistory();
  const linkStyle = {};

  const DropdownLinkStyle = {
    fontSize: 16,
    fontWeight: 400,
  };

  const CompanyData = Company[0];

  return (
    <>
      <header className="main d-flex justify-content-center p-0 py-2">
        <div className="container row d-flex p-0 m-0">
          <div className="column col-md-1 p-0 d-flex justify-content-start">
            <img src={img2} className="logo" alt={CompanyData.name + "logo"} />
          </div>

          <div className="column col-md-3 p-0 d-flex justify-content-start align-items-center">
            <a className="text-decoration-none" href="/#Home">
              <h1 className="pl-lg-3 headerH1">{CompanyData.name}</h1>
              <span className="tagline">{CompanyData.tagline}</span>
            </a>
          </div>

          <div className="column col-md-8 col-8 p-0 d-flex align-items-center justify-content-sm-center">
            <Navbar expand="lg" style={{ padding: 0 }}>
              <Container fluid className="navigation-bar container">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav
                    className="me-auto my-2 my-lg-0 container "
                    style={{
                      display: window.innerWidth < 1024 ? "flex" : "contents",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Nav.Link
                      className={
                        (currentComponent === "Home" ||
                        currentComponent === "home"
                          ? "activeLink"
                          : "") + " menu-link linksPadding "
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("Home")}
                    >
                      Home
                    </Nav.Link>

                    <Nav.Link
                      className={
                        (currentComponent === "AboutUs" ? "activeLink" : "") +
                        " menu-link linksPadding"
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("AboutUs")}
                    >
                      Profile
                    </Nav.Link>

                    <Nav.Link
                      className={
                        (currentComponent === "Services" ? "activeLink" : "") +
                        " menu-link linksPadding"
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("Products")}
                    >
                      Products
                    </Nav.Link>

                    <Nav.Link
                      className={
                        (currentComponent === "Services" ? "activeLink" : "") +
                        " menu-link linksPadding"
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("Services")}
                    >
                      Services
                    </Nav.Link>

                    <Nav.Link
                      className={
                        (currentComponent === "ContactUs" ? "activeLink" : "") +
                        " menu-link linksPadding"
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("ContactUs")}
                    >
                      Contact Us
                    </Nav.Link>

                    <div className="login-options">
                      <FontAwesomeIcon icon={faSignIn} />
                      <NavDropdown
                        title="Login"
                        style={{
                          marginRight: "0px",
                          color: "#22a98b",
                          fontWeight: "400",
                          fontSize: "21px",
                        }}
                        className="login-option-link"
                        align={"end"}
                        id="navbarScrollingDropdown"
                      >
                        <NavDropdown.Item
                          style={DropdownLinkStyle}
                          onClick={() => history.push("/Customer")}
                        >
                          Customer
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          style={DropdownLinkStyle}
                          onClick={() => history.push("/Company")}
                        >
                          Company
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          style={DropdownLinkStyle}
                          onClick={() => history.push("/Salesman")}
                        >
                          Salesman
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
