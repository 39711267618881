import { Button, DatePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ApiHandler from "../../../ApiHandler";
import GlobalVariable from "../../../GlobalVariable";
import LoaderTri from "../../Loader/LoaderTri";

const initialValues = {
  from: dayjs(),
  to: dayjs(),
};

const SalesmanReport = () => {
  const [dates, setDates] = useState(initialValues);

  const [showLedgerData, setShowLedgerData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reportDataList, setReportDataList] = useState([]);
  const [openDate, setOpenDate] = useState(false);
  const [viewdata, setViewdata] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const [dynamicUrl, setDynamicUrl] = useState("");

  // useEffect(() => {
  //     ApiHandler.get({ url: "api/OwnAppConfigs/" + GlobalVariable.wcClient })//GlobalVariable.wcClient
  //         .then(res => {
  //             setDynamicUrl(res.localUrl);

  //         })
  //         .catch(err => {
  //             console.error(err);
  //         })

  //     return () => {

  //     }
  // }, [])

  function toViewData() {
    setViewdata(true);
    setIsLoading(true);
    setShowLedgerData([]);
    setReportDataList([]);
    const user = JSON.parse(localStorage.getItem("user-info"));
    const dynamicUrl = localStorage.getItem("localUrlSales");

    ApiHandler.post({
      url: dynamicUrl + "api/OwnApp/GetSalesmanSalesReport",
      data: {
        code: user.code,
        startdate: dayjs(dates.from).format("YYYYMMDD"),
        enddate: dayjs(dates.to).format("YYYYMMDD"),
      },
      useUserUrl: true,
    })
      .then((result) => {
        console.log(result);
        console.log(result.data);
        setReportDataList(result.data);
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);
          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="row">
      <div className="col-xl-8 offset-xl-2 ">
        <h5
          style={{
            marginTop: "20px",
            fontFamily: "sans-serif",
            // fontSize: "17px",
          }}
        >
          Enter Details to view Report
        </h5>

        <div className="row ">
          <div className="col-lg-3  ">
            <DatePicker.RangePicker
              size="middle"
              className=" mb-0 mr-2"
              value={[dates.from, dates.to]}
              format="DD-MM-YYYY"
              onChange={(e) => {
                console.log(e);

                if (e) {
                  setDates({
                    from: e[0],
                    to: e[1],
                  });
                } else {
                  setDates({ from: null, to: null });
                }
              }}
            />
          </div>
          <div className="col-lg-2  ">
            <Button
              type="button"
              className="btn   btn-primary btn-sm"
              onClick={toViewData}
            >
              View
            </Button>
          </div>
          <br />
        </div>
        {isLoading && <LoaderTri />}
        <div className="row ">
          <div className="col-lg-12  ">
            <table className="table " border={1} style={{ marginTop: 50 }}>
              <thead>
                <tr>
                  <th>BillNo</th>
                  <th>BillDate</th>
                  <th>Name</th>
                  <th>Place</th>
                  <th>Amount</th>
                  <th>Taxable</th>
                  <th>GST</th>
                  {/* "_color": "Y" */}
                </tr>
              </thead>
              <tbody>
                {reportDataList.map((data, i) => {
                  return (
                    <tr
                      key={i}
                      className={data._color === "Y" ? "table-danger" : ""}
                      style={
                        {
                          // backgroundColor: data._color === "Y" ? "red" : "white"
                        }
                      }
                    >
                      <td>{data.billNo}</td>
                      <td>{data.billDate}</td>
                      <td>{data.name}</td>
                      <td>{data.place}</td>
                      <td>{data.amount}</td>
                      <td>{data.taxable}</td>
                      <td>{data.gst}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesmanReport;
