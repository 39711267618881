import React from "react";
import img2 from "../../src/Images/logo.png";
import iconPayStore from "../assets/images/badge-en.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faMobileScreen,
  faEnvelope,
  faPhone,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import '../Styles/footer.scss'
import { Company, BankDetails } from "../assets/Data";

function Footer() {

  return (
    <>
      <footer
        className="footer pt-5"
        style={{
          bottom: 0,
          // position: "fixed",
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px -7px 1rem 4px",
        }}
      >
        <div className="container">
          <div className="row m-0 footer-info">
            <div className="col-md-5 col-sm-12">
              <h4 className="small-devices-text">ADDRESS</h4>
              <ul className="footer-list bordered-icons">
                <li>
                  <FontAwesomeIcon icon={faHouse} />
                  <p
                    className="address-section small-devices-text mr-1"
                    dangerouslySetInnerHTML={{ __html: Company[0].address[0] }}
                  ></p>
                </li>

                {Company[0].phone.primary.map((item, index) => (
                  <li key={index}>
                    {item.icon === "mobile" ? (
                      <FontAwesomeIcon icon={faMobileScreen} />
                    ) : (
                      <FontAwesomeIcon icon={faPhone} />
                    )}
                    <p className="phone small-devices-text mr-1">
                      Call Us : {item.number}
                    </p>
                  </li>
                ))}

                <li className="email small-devices-text">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <p className="mr-1">
                    <a href={`tel:${Company[0].email}`}>
                      {" "}
                      Email : {Company[0].email}
                    </a>
                  </p>
                </li>
              </ul>
            </div>

            <div className="col-md-3 col-sm-12">
              <h4 className="small-devices-text">USEFUL LINKS</h4>

              <ul className="footer-list">
                <li>
                  <p className="small-devices-text">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <a href="/#Home">Home</a>
                  </p>
                </li>

                <li>
                  <p className="small-devices-text">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <a href="/#AboutUs">Profile</a>
                  </p>
                </li>

                <li>
                  <p className="small-devices-text">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <a href="/#Products">Products</a>
                  </p>
                </li>

                {/* <li>
                  <p className="small-devices-text">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <a href="/ProductList">Products</a>
                  </p>
                </li> */}

                {/* <li>
                  <p className="small-devices-text">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <a href="/#OurVision">Our Vision</a>
                  </p>
                </li>
                <li>
                  <p className="small-devices-text">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <a href="/#OurValues">Our Values</a>
                  </p>
                </li> */}

                <li>
                  <p className="small-devices-text">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <a href="/#Services">Services</a>
                  </p>
                </li>

                <li>
                  <p className="small-devices-text">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <a href="/#ContactUs">Contact Us</a>
                  </p>
                </li>
              </ul>
            </div>

            <div className="col-md-4 col-sm-12">
              <h4 className="small-devices-text">OPENING HOURS</h4>

              <table>
                <tbody>
                  <tr>
                    <th className="light-font">Monday – Saturday</th>
                    <td>09:00 AM - 09:00 PM</td>
                  </tr>
                  <tr>
                    <th className="light-font">Sunday -----------------</th>
                    <td>09:00 AM - 06:00 PM</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <section className="copyright">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <p className="light-font">
                    Copyright © 2019 - All rights reserved
                  </p>
                </div>

                <div className="col-md-6 col-sm-12">
                  <p className="small-devices-text">
                    Powered By:
                    <a
                      href="http://www.relysoft.in/"
                      target="_blank"
                      rel="noreferrer"
                      className="powered-by text-sm-center"
                    >
                      relysoft.in
                    </a>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
