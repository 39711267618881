import React from 'react'
import { Link } from "react-router-dom";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import '../../Styles/Products/NavBar.scss'

function NavBar({ handleNavLinkClick, currentComponent }) {

  const linkStyle = {};

  return (
    <div className="more-links my-sm-5 my-2 p-0">
      <Navbar expand="lg" expanded style={{ padding: 0 }}>
        <Container fluid className="navigation-bar container p-0">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto my-2 my-lg-0 container p-0"
              style={{
                display: window.innerWidth < 1024 ? "flex" : "contents",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
            >
              <ul className="menu-lists">
                <li>
                  <Nav.Link onClick={() => handleNavLinkClick("CompanyList")}>
                    Company List
                  </Nav.Link>
                </li>

                <li>
                  <Nav.Link onClick={() => handleNavLinkClick("Products")}>
                    Products
                  </Nav.Link>
                </li>

                <li>
                  <Nav.Link onClick={() => handleNavLinkClick("New_Product")}>
                    CompanyList
                  </Nav.Link>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavBar