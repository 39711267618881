import React, { useEffect, useRef, useState } from "react";
import SalesManHeader from "./SalesManHeader";
import Typeahead from "../Typeahead";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import GlobalVariable from "../../GlobalVariable";
import ApiHandler from "../../ApiHandler";
import { Result } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const productsColumns = [
  {
    header: "Product Name",
    field: "prodName",
    cols: 3,
  },
  {
    header: "Company",
    field: "companyName",
    cols: 2,
  },
  {
    header: "Pack",
    field: "case1",
    cols: 2,
  },

  {
    header: "Scheme",
    field: "scheme",
    cols: 2,
  },
  {
    header: "stock",
    field: "stock",
    cols: 1,
  },
  {
    header: "MRP",
    field: "mrp",
    cols: 1,
  },
  {
    header: "Rate",
    field: "rate",
    cols: 1,
  },
];

const orderItem = {
  wProdCode: "",
  qty: 0,
  free: 0,
  discPerc: 0,
  prodname: "",
  entryTime: "2024-01-18T10:32:29.142Z",
  rProdCode: "",
  pack: "",
  wBarcode: "",
  sourceId: 0,
};
const initialOrder = {
  wClientid: GlobalVariable.wcClient,
  CustCode: "",
  repcode: 4,
  uniqueOrderID: "",
  orderType: "web",
  rclientid: null,
  urgent: 0,
  remarks: "",
  mobNo: "",
  orderItems: [],
};
const success = "SUCCESS";
const failure = "FAILURE";
export default function SmOrder(props) {
  const [smShow, setSmShow] = useState(false);
  const [newProduct, setNewProduct] = useState([]);
  // const [filterVal, setFilterVal] = useState('');
  const [searchApiData, setSearchApiData] = useState([]);
  const [compName, setCompName] = useState();
  const [prodName, setProdName] = useState();
  const [case1, setCase1] = useState();
  const [scheme, setScheme] = useState(0);
  const [stock, setStock] = useState();
  const [mrp, setMrp] = useState();
  const [rate, setRate] = useState();
  const [increasePrice, setIncreasePrice] = useState(0.0);
  // const [isOpen, setIsOpen] = useState(false)
  const [countNum, setCountNum] = useState(0);
  const modalQty = useRef();
  // const [comp1, setComp1] = useState();
  const [prd1, setPrd1] = useState();
  const [cs1, setCs1] = useState();
  //const [sc1, setSc1] = useState("");
  const [mrp1, setMrp1] = useState();
  const [visible, setVisible] = useState(false);
  const [noOfquantity, setNoOfQuantity] = useState(1);
  // const [allData, setAllData] = useState([]);
  // const [noAvailableProduct, setNoAvailableProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  const [input, setInput] = useState("");
  //  const [customer, setCustomer] = useState([]);
  // const [cstName, setCstName] = useState('');
  // const [cstPlace, setCstPlace] = useState('')
  const [order, setOrder] = useState({
    ...initialOrder,
    orderItems: [],
  });
  const [remarksMessage, setRemarksMessage] = useState("");
  const [productCode, setProductCode] = useState("");
  //   const [cstOrders, setcstOrders] = useState(order.cName);
  //   const [cstorderPlace, setCstOrderPlace] = useState();
  const [showsalesStock, setShowsalesStock] = useState([]);

  var customerTemp = JSON.parse(localStorage.getItem("customerInCompany"));
  const customerOrder = customerTemp ? customerTemp : null;
  //   const customerOrderName = useSelector((state) => state.order.cName);
  //   const customerOrderPlace = useSelector((state) => state.order.cplace);
  const customerOrderName = customerTemp.custName;
  const customerOrderPlace = customerTemp.place;

  let pickuporder = "PickUp Order";
  let deliveryOrder = "Delivery Order";
  let history = useHistory();

  //   console.log({ customerOrder, customerOrderPlace });

  useEffect(() => {
    if (smShow) {
      setTimeout(() => {
        modalQty?.current?.focus();
      });
    }

    return () => {};
  }, [smShow]);

  useEffect(() => {
    if (customerOrder.custCode) {
      setOrder((v) => {
        // const user = JSON.parse(localStorage.getItem('user-info'))
        return {
          ...v,
          custCode: customerOrder.custCode,
        };
      });
    }
  }, [customerOrder?.custCode]);

  const user = JSON.parse(localStorage.getItem("user-info"));
  const token = JSON.parse(localStorage.getItem("user-info"));

  useEffect(() => {
    const userForCode = JSON.parse(localStorage.getItem("user-info"));
    userForCode &&
      setOrder((v) => {
        return { ...v, repcode: userForCode.code };
      });
  }, []);

  useEffect(() => {
    ApiHandler.get({
      url: "/api/OwnAppConfigs/" + GlobalVariable.wcClient,
    })
      .then((res) => {
        console.log(res);
        console.log(res?.showStocktoSMan);

        setShowsalesStock(res?.showStocktoSMan);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function SearchHandler(text) {
    console.log(user);
    setLoading(true);
    ApiHandler.post({
      url: "/api/Search/ProductByName/",
      data: {
        wClientid: GlobalVariable.wcClient,
        search: text,
        areaCode: user.areaCode + "" + 0,
      },
    })
      .then((res) => {
        console.log(res.data);
        const mappedData = res.data.map((data) => {
          return {
            // ...data, stock: data.stock + ""
            ...data,
            stock:
              showsalesStock !== true
                ? data.stock && data.stock > 500
                  ? "500+"
                  : data.stock + " "
                : data.stock > 0
                ? "YES"
                : "NO",
          };
        });
        setNewProduct(mappedData);
        setSearchApiData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  //   useEffect(() => {
  //     setRemarksMessage();
  //   }, []);

  useEffect(() => {
    let total = 0;
    console.log({ orderItems: order.orderItems });
    order.orderItems &&
      order.orderItems.forEach((data) => {
        console.log();
        total +=
          parseFloat(data.noOfquantity ? data.noOfquantity : 0) *
          parseFloat(data.mrp1 ? data.mrp1 : 0);
      });
    setIncreasePrice(parseFloat(total.toFixed(2)));
    return () => {};
  }, [order.orderItems]);

  function addValuehandler(e) {
    addInItems();
  }

  function addProductOnEnterHandler(e) {
    if (e.key === "Enter") {
      addInItems();
    }
  }
  const addInItems = () => {
    if (!parseFloat(noOfquantity) > 0) {
      alert("Please Enter Quantity ...!");
      return;
    }
    setSmShow(false);

    const date = new Date();

    const orderItemData = {
      slno: countNum + 1,
      PrdCode: productCode,
      comp1: compName,
      prd1: prodName,
      cs1: case1,
      sc1: scheme,
      mrp1: mrp,
      noOfquantity,
      showTime: new Date(),
    };

    setOrder((v) => {
      return {
        ...v,
        orderItems: [...v.orderItems, orderItemData],
      };
    });

    setCountNum((c) => c + 1);

    const CustomerOrdersLocalStorage = window.localStorage.getItem(
      "Customer-Orders-" + customerOrder.custCode
    );
    const saveData = JSON.parse(CustomerOrdersLocalStorage);

    const saveToAllData = {
      ...saveData,
      orderItem: saveData
        ? [...saveData.orderItem, orderItemData]
        : [orderItemData],
      cstOrders: customerOrderName,
      cstorderPlace: customerOrderPlace,
      prd1: prd1,
      cs1: cs1,
      mrp1: mrp1,
    };

    window.localStorage.setItem(
      "Customer-Orders-" + customerOrder.custCode,
      JSON.stringify(saveToAllData)
    );
  };
  function deletehandler(record) {
    console.log({ record, order });
    // setAllData(
    //     allData.filter((product) => product.slno !== record.slno)
    // );
    setOrder((v) => ({
      ...v,
      orderItems: v.orderItems.filter(
        (product) => product.slno !== record.slno
      ),
    }));
  }
  const [productSearch, setProductSearch] = useState({
    options: [],
    selected: [],
    search: "",
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [submitOrderNo, setSubmitOrderNo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function submitDataHandler(e) {
    var customer = JSON.parse(localStorage.getItem("customerInCompany"));
    if (!customer) {
      alert("Could Not Find Customer Please Go Back and select Customer ");
    }
    var date = new Date();
    const uniqueOrderID =
      GlobalVariable.wcClient +
      "_" +
      user.code +
      "_" +
      customerOrder.custCode +
      "_" +
      date.getDay() +
      date.getMonth() +
      date.getFullYear() +
      date.getHours() +
      date.getMinutes() +
      date.getSeconds() +
      date.getMilliseconds() * 100000;

    const arr = order?.orderItems.map((e) => {
      return {
        uniqueOrderID: uniqueOrderID,
        SrNo: e.slno,
        CustCode: customerOrder.custCode,
        wProdCode: e.PrdCode,
        qty: e.noOfquantity,
        free: 0,
        // prodname: e.prd1 || e.prodName,
        prodname: "",
        entryTime: e.showTime,
        rProdCode: "",
        pack: e.cs1 || e.case1,
        wBarcode: "",
        sourceId: 0,
      };
    });
    let senddata = order;

    //senddata.SrNo = ++e.slno;
    senddata.remarks = remarksMessage;
    senddata.orderItems = arr;
    senddata.CustCode = customerOrder.custCode;
    // senddata.CustCode = "";
    senddata.uniqueOrderID = uniqueOrderID;
    // senddata.remarks =
    //   senddata.remarks === "--Select--" ? "" : senddata.remarks;
    ApiHandler.post({ url: "/api/Orders/WritePopsOrders/", data: senddata })
      .then((res) => {
        console.log(res.data);

        localStorage.removeItem("Customer-Orders-" + customerOrder.custCode);
        setShowSuccessMessage(true);
        setOrderStatus(success);
        setSubmitOrderNo(res.data.orderNo);

        clearFunction();
      })
      .catch((err) => {
        setOrderStatus(failure);
        console.error(err);
        // window.location.reload();
        setErrorMessage(
          typeof err?.response?.data === "string" ? err?.response?.data : ""
        );
      })
      .finally(() => {
        setShowSuccessMessage(true);
      });
    console.log(senddata);
    setCountNum(senddata.SrNo + 1);
  }
  const clearFunction = () => {
    setOrder(initialOrder);
    setCountNum(0);
  };

  useEffect(() => {
    console.log("ORDER_DATA ", order);

    return () => {};
  }, [order]);

  useEffect(() => {
    const CustomerOrdersLocalStorage = window.localStorage.getItem(
      "Customer-Orders-" + customerOrder.custCode
    );
    const saveData = JSON.parse(CustomerOrdersLocalStorage);
    console.log(saveData);

    console.log({ saveData, custcode: customerOrder.custCode });
    if (saveData) {
      if (saveData.orderItem) {
        Array.isArray(saveData.orderItem) &&
          setCountNum(saveData.orderItem.length);
        setOrder((v) => {
          return {
            ...v,
            orderItems: saveData.orderItem,
          };
        });
      }

      setPrd1((c) => (!c ? saveData.prd1 : c));
      setCs1((c) => (!c ? saveData.cs1 : c));
      setMrp1((c) => (!c ? saveData.mrp1 : c));
    }
  }, [customerOrder.custCode]);

  //   console.log("orderListing", order);
  useEffect(() => {
    const CustomerOrdersLocalStorage = window.localStorage.getItem(
      "Customer-Orders-" + customerOrder.custCode
    );
    const saveData = JSON.parse(CustomerOrdersLocalStorage);

    const saveToAllData = {
      ...saveData,
      //   orderItem: order.orderItems,
      cstOrders: customerOrderName,
      cstorderPlace: customerOrderPlace,
      prd1: prd1,
      cs1: cs1,
      mrp1: mrp1,
    };
    console.log({ saveToAllData });

    if (
      saveToAllData.orderItem &&
      saveToAllData.orderItem.length > 0 &&
      customerOrder.custCode
    ) {
      window.localStorage.setItem(
        "Customer-Orders-" + customerOrder.custCode,
        JSON.stringify(saveToAllData)
      );
    }
  }, [
    // order.orderItems,
    // order.orderItems.length,
    customerOrderName,
    customerOrderPlace,
    prd1,
    cs1,
    mrp1,
    customerOrder.custCode,
  ]);

  function logout() {
    localStorage.removeItem("customerInCompany");
    window.localStorage.removeItem("user-info");
    //history.push('/Customer');
    window.location.href = "/SalesMan";
  }

  return (
    <>
      <SalesManHeader />
      <div className="row">
        <div className="col-xl-8 offset-xl-2 ">
          <div className="UserDiv align-self-end text-end ">
            <p>Hello! {user.name}</p>

            <h6>
              <span style={{ color: "black" }}>Customer: </span>
              <span style={{ color: "rgb(101, 100, 100)" }}>
                {customerOrderName + "," + customerOrderPlace}
              </span>
            </h6>

            <button
              className="backBTN"
              onClick={() => {
                localStorage.removeItem("customerInCompany");
                history.goBack();
              }}
            >
              Back
            </button>

            <button onClick={logout} className="backBTN ml-2">
              Log Out
            </button>
          </div>
        </div>
      </div>

      <h1
        style={{
          textAlign: "center",
          fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
          fontSize: "28px",
          fontWeight: "600",
          padding: "20px",
        }}
      >
        Place Order
      </h1>

      <div className="row">
        <div
          className="  col-xl-4 col-lg-6 col-md-6 col-sm-6 offset-xl-2"
          id="searchbar"
        >
          <div className="">
            <Typeahead
              //stock={stock}
              style={{ border: "1px solid #66cccc", width: "1145px" }}
              placeholder="Search Product"
              id="searchProduct"
              className="searchProduct"
              labelKey="companyName"
              name="companyName"
              columns={productsColumns}
              colWidth={window.screen.width}
              isLoading={false}
              //when typing in the text box search in handled here
              onSearch={(e) => {
                console.log(e);
                setProductSearch({ ...productSearch, search: e });

                SearchHandler(e);
              }}
              // when value is choosen or selected
              onChange={(e) => {
                console.log(e[0]);

                const elm = e[0];
                setSmShow(true);
                setVisible(false);
                setCompName(elm.companyName);
                setProdName(elm.prodName);
                setCase1(elm.case1);
                setScheme(elm.scheme);
                setStock(elm.stock);
                setMrp(elm.mrp);
                setRate(elm.rate);
                setProductCode(elm.prodCode);
              }}
              options={newProduct ? newProduct : []}
              selected={productSearch.selected ? productSearch.selected : []}
            />
          </div>
        </div>
      </div>

      <form className="row">
        <div className="col-xl-8 col-lg-6 col-md-6 col-sm-6 offset-xl-2">
          <Table striped bordered variant=" ">
            <thead>
              <tr
                style={{
                  backgroundColor: "#00aff0",
                  color: "white",
                  border: "1px solid black",
                }}
              >
                <th>#</th>
                <th>Product Name</th>
                <th>Packing</th>
                <th>Scheme</th>
                <th>MRP</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {order.orderItems &&
                order.orderItems.map((elmOrder, i) => {
                  return (
                    <tr key={i} style={{ border: "1px solid black" }}>
                      <td>{elmOrder.slno}</td>
                      <td>{elmOrder.prd1 || elmOrder.prodName}</td>
                      <td>{elmOrder.cs1 || elmOrder.case1}</td>
                      <td>{elmOrder.sc1 || elmOrder.scheme}</td>
                      <td>{elmOrder.mrp1 || elmOrder.mrp}</td>
                      <td>{elmOrder.noOfquantity}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          deletehandler(elmOrder);
                        }}
                      >
                        Delete{" "}
                        <AiOutlineDelete
                          style={{ marginLeft: "7px", color: "red" }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

        <Modal
          size="lg"
          style={{ width: "95%" }}
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton style={{ backgroundColor: "#2EC8A6" }}>
            <Modal.Title>
              <span style={{ color: "white", fontWeight: "300" }}>
                {prodName}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col
                  sm={8}
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#737373",
                    fontWeight: "700",
                  }}
                >
                  {compName}
                </Col>
                <Col
                  sm={8}
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#757575",
                    fontWeight: "500",
                  }}
                >
                  Scheme : {scheme}
                </Col>
              </Row>
              <Row xs={1} md={4}>
                <Col
                  sm
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#757575",
                    fontWeight: "500",
                  }}
                >
                  Packing : {case1}
                </Col>
                <Col
                  sm
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#757575",
                    fontWeight: "500",
                  }}
                >
                  Rate : {rate}
                </Col>
                <Col
                  sm
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#757575",
                    fontWeight: "500",
                  }}
                >
                  Stock : {stock}
                </Col>
                <Col
                  sm
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#757575",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  {"  "}
                </Col>
              </Row>
              <Row xs={1} md={4}>
                <Col
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#757575",
                    fontWeight: "500",
                  }}
                >
                  case : -,-
                </Col>
                <Col
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#757575",
                    fontWeight: "500",
                  }}
                >
                  Min : 0
                </Col>
                <Col
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#757575",
                    fontWeight: "500",
                  }}
                >
                  MRP : {mrp}
                </Col>
                <Col
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    padding: "9px",
                    color: "#757575",
                    fontWeight: "500",
                  }}
                >
                  Quantity :
                  <input
                    ref={modalQty}
                    type="number"
                    // value={numbers}
                    style={{ width: "100px" }}
                    onChange={(e) => setNoOfQuantity(e.target.value)}
                    onKeyPress={addProductOnEnterHandler}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Button
            className="BtnsA"
            //  (e) => (noOfquantity.trim() !== 0 ? alert(noOfquantity) : alert("empty number"))
            onClick={addValuehandler}
          >
            Add Product
          </Button>
        </Modal>

        <div className="row m-0">
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 offset-xl-2">
            <div className="DropC">
              <h5 style={{ fontFamily: "Futara", color: "rgb(89, 91, 91)" }}>
                Priority
              </h5>
              <select>
                <option>Normal</option>
                <option>Urgent</option>
              </select>
            </div>
            <div className="remark  ">
              <h5 style={{ fontFamily: "Futara", color: "rgb(89, 91, 91)" }}>
                Remarks
              </h5>

              <div className="dropdowns">
                <span className="dropdwnsName">
                  {value ? value : "--Select--"}
                  {input}
                </span>
                <div className="dropdown-content">
                  <li
                    name="PickUpOrder"
                    onClick={() => setValue(pickuporder)}
                    value="PickupOrder"
                    style={{ color: "rgb(89, 91, 91)" }}
                  >
                    Pickup Order
                  </li>
                  <li
                    onClick={() => setValue(deliveryOrder)}
                    value="Delivery Order"
                    style={{ color: "rgb(89, 91, 91)" }}
                  >
                    Delivery Order
                  </li>
                  <li
                    onClick={() => setInput()}
                    value="Remarks"
                    style={{ fontWeight: "bold" }}
                  >
                    Remarks
                    <textarea
                      name="Text1"
                      cols="68"
                      rows="2"
                      onChange={(e) => setValue(e.target.value)}
                    ></textarea>
                  </li>
                </div>
              </div>
              <h5
                style={{
                  marginTop: "50px",
                  marginBottom: "50px",
                  fontFamily: "Futara",
                  color: "rgb(89, 91, 91)",
                }}
              >
                Approx Order Value : Rs.
                {increasePrice}
              </h5>
            </div>

            <button
              type="Button"
              className="SubmitButton "
              onClick={submitDataHandler}
            >
              Submit
            </button>
          </div>
        </div>

        <Modal
          show={showSuccessMessage}
          onHide={() => setShowSuccessMessage(false)}
        >
          <div
            className=" "
            style={{
              position: "absolute",
              right: 5,
              top: 5,
            }}
          >
            <FontAwesomeIcon
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setShowSuccessMessage(false);
              }}
              icon={faClose}
              size="2x"
              color="red"
            />
          </div>

          {orderStatus === success && (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                style={{ marginLeft: "180px", marginTop: "20px" }}
                width="100"
                height="100"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#c8e6c9"
                  d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                ></path>
                <path
                  fill="#4caf50"
                  d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
                ></path>
              </svg>

              <h5
                style={{
                  textAlign: "center",
                  color: " rgb(83, 82, 82)",
                  paddingBottom: "10px",
                  paddingTop: "20px",
                }}
              >
                Your order was successfully submitted!
              </h5>

              <h6 className="orderNo" style={{ marginLeft: "120px" }}>
                Your Order No :
                <span style={{ color: "green", fontWeight: "bold" }}>
                  {" " + submitOrderNo}
                </span>
              </h6>
            </>
          )}
          {orderStatus === failure && (
            <>
              <Result
                status="error"
                title="Submission Failed"
                subTitle="Please check and modify the following information before resubmitting."
              >
                <div className="desc">
                  <p>
                    <div
                      strong
                      style={{
                        fontSize: 16,
                      }}
                    >
                      The content you submitted has the following error:
                    </div>
                  </p>
                  {errorMessage}
                </div>
              </Result>
            </>
          )}
        </Modal>
      </form>
    </>
  );
}
