const Company = [
  {
    name: "M/s. Guddodgi Pharmaceutical Distributors",
    fullName: "M/s. Guddodgi Pharmaceutical Distributors",
    tagline: "Pharmaceutical Distributors",
    phone: {
      primary: [
        {
          label: "Phone",
          number: "08352-251465",
        },
      ],
      alternate: [
        {
          label: "Fax",
          number: "08352-242142",
        },
      ],
    },
    email: "Guddodgi242@Gmail.Com",
    address: [
      "Address : Near Meenaxi Chowk, Tilak Road,Godbole Mala, Vijayapura - 586 101, Karnataka.",
      "Near Meenaxi Chowk, Tilak Road,Godbole Mala, Vijayapura - 586 101, Karnataka.",
    ],
  },
];

const BankDetails = [
  {
    label: "Bank Details:",
    value: "",
  },
  {
    label: "A/C. No.:",
    value: "",
  },
  {
    label: "IFSC Code:",
    value: "",
  },
];

export { Company, BankDetails };
