import React from "react";

import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../../snippets/Footer";

import "../../App.scss";
import "./css/home.css";

import Header from "../../snippets/Header";
import AboutUs from "../AboutUs/AboutUs";
import ContactUs from "../ContactUs/ContactUs";
import OurVision from "../OurVision/OurVision";
import OurValues from "../OurValues/OurValues";
import HomeComponent from "./components/HomeComponent";
import Services from "../Services/Services";
import Product from "../../Product";
import New_Product from "../../New_Product";
import CompanyList from "../../CompanyList";

function Home(props) {
  const pageName =
    props && props.match && props.match.params && props.match.params.pageName;
  // console.log(pageName);
  const history = useHistory();

  const [currentComponent, setCurrentComponent] = useState(pageName);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substr(1);
      setCurrentComponent(hash || "Home");
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const renderComponent = useCallback(() => {
    console.log({ currentComponent });
    switch (currentComponent) {
      case "AboutUs":
        return <AboutUs />;
      case "OurVision":
        return <OurVision />;
      case "OurValues":
        return <OurValues />;
      case "Services":
        return <Services />;
      case "CompanyList":
        return (
          <CompanyList
            handleNavLinkClick={handleNavLinkClick}
            currentComponent={currentComponent}
          />
        );
      case "Products":
        return (
          <Product
            handleNavLinkClick={handleNavLinkClick}
            currentComponent={currentComponent}
          />
        );
      case "New_Product":
        return (
          <New_Product
            handleNavLinkClick={handleNavLinkClick}
            currentComponent={currentComponent}
          />
        );
      case "ContactUs":
        return <ContactUs />;
      default:
        return <HomeComponent />;
    }
  }, [currentComponent]);

  const handleNavLinkClick = (component) => {
    history.push("#" + component);
    setCurrentComponent(component);
  };

  return (
    <>
      <Header
        currentComponent={currentComponent}
        handleNavLinkClick={handleNavLinkClick}
      />

      <div
        className="middle"
        style={{
          padding: 0,
        }}
      >
        {renderComponent()}
      </div>
      <Footer />
    </>
  );
}
export default Home;
