import CustomerFooter from "./CustomerFooter";
import HeaderCustomer from "../../snippets/Customer/HeaderCustomer";
import "./Customer.css";
import { useEffect, useRef } from "react";
import axios from "axios";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ApiHandler from "../../ApiHandler";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import LoaderTri from "../Loader/LoaderTri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const initialValues = {
  from: dayjs(),
  to: dayjs(),
};
export default function DownloadInvoice() {
  const [dates, setDates] = useState(initialValues);

  const [showInvoiceData, SetShowInvoiceData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [alldatesData, setAlldatesData] = useState([]);
  const [openDate, setOpenDate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewdata, setViewdata] = useState(false);
  const [range, setRange] = useState([
    {
      startdate: new Date(),
      endDate: addDays(new Date(), ""),
      key: "selection",
    },
  ]);

  const refOne = useRef(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.removeEventListener("keydown", hideOnEscape, true);
      document.removeEventListener("click", hideOnClickOutside, true);
    };
  }, []);

  const hideOnEscape = (e) => {
    console.log(e.key);
    if (e.key === "Escape") {
      setOpenDate(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpenDate(false);
    }
  };

  const handleSelects = (date) => {
    let filtered = alldatesData.filter((product) => {
      let productDate = new Date(product["date"]);
      return (
        productDate >= date.selection.startDate &&
        productDate <= date.selection.endDate
      );
    });
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    SetShowInvoiceData(filtered);
    console.log(date.selection.startDate);
    console.log(date.selection.endDate);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  function toGetData() {
    const localUrl = JSON.parse(localStorage.getItem("LocalURL"));
    const user = JSON.parse(localStorage.getItem("user-info"));
    setViewdata(true);
    setIsLoading(true);
    ApiHandler.post({
      url: localUrl + "api/OwnApp/GeCustomerBillList",
      data: {
        startdate: dayjs(dates.from).format("YYYYMMDD"),
        enddate: dayjs(dates.to).format("YYYYMMDD"),
        // startdate: moment(selectionRange.startDate).format("YYYYMMDD"),
        // enddate: moment(selectionRange.endDate).format("YYYYMMDD"),
        code: user.code,
      },
      useUserUrl: true,
    })
      .then((result) => {
        console.log(result);
        console.log(result.data);
        setAlldatesData(result.data);
        SetShowInvoiceData(result.data);
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);
          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
      })

      .finally(() => {
        setIsLoading(false);
      });
  }

  // setCsvFile(localUrl + "/bills/" + 15201 + "_" + elm.billNo + "_" + elm.date.split('/') + ".csv")
  //setCsvFile(localUrl + "bills/" + 15201 + "_" + elm.billNo + "_" + elm.date.replaceAll('/', '', 'yyyymmdd') + ".csv")
  // console.log(localUrl + "bills/" + 15201 + "_" + elm.billno + "_" + elm.date.replaceAll('/', '') + ".csv")
  const [isDownloading, setIsDownloading] = useState(false);
  function getURLCSV(elm, type) {
    setIsDownloading(true);
    const user = JSON.parse(localStorage.getItem("user-info"));
    const localUrl = JSON.parse(localStorage.getItem("LocalURL"));
    ApiHandler.post({
      url: localUrl + "api/OwnApp/CustomerInvoiceDownload",
      data: {
        type: elm._type,
        billno: elm.billNo,
        date: stringConvert(elm),
        custCode: (user.code + "").toString(),
        fileType: type,
        // pdf "xls"
      },
      useUserUrl: true,
    })
      .then((result) => {
        console.log(result);
        window.location.href =
          localUrl +
          "bills/" +
          user.code +
          "_" +
          elm.billNo +
          "_" +
          stringConvert(elm) +
          "." +
          type;
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);
          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
        toast.error("Failed To Download ", type);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }
  console.log({ showInvoiceData });

  const stringConvert = (elm) => {
    console.log(elm);
    let string = elm.date + "";
    string = string.replaceAll("/", "");
    console.log(
      string.substring(4, 8) + string.substring(2, 4) + string.substring(0, 2)
    );
    return (
      string.substring(4, 8) + string.substring(2, 4) + string.substring(0, 2)
    );
  };

  return (
    <>
      <HeaderCustomer />
      <div style={{ minHeight: window.innerHeight / 1.3 }}>
        <div className="row m-0">
          <div className="col-lg-10 offset-lg-1">
            <h5
              style={{
                marginTop: "20px",
                fontFamily: "sans-serif",
                // fontSize: "17px",
              }}
            >
              Select details to view Invoice
            </h5>
          </div>
          <div className="col-lg-10 offset-lg-1">
            <div className="row  ">
              <div className="col-lg-3  ">
                <DatePicker.RangePicker
                  size="middle"
                  className="mb-2"
                  value={[dates.from, dates.to]}
                  format="DD-MM-YYYY"
                  onChange={(e) => {
                    console.log(e);

                    if (e) {
                      setDates({
                        from: e[0],
                        to: e[1],
                      });
                    } else {
                      setDates({ from: null, to: null });
                    }
                  }}
                />
              </div>
              <div className="col-lg-4  ">
                <button
                  type="button"
                  className="btn mb-3 btn-primary btn-sm"
                  onClick={toGetData}
                >
                  View
                </button>
              </div>
              <div className="col-lg-5 text-center ">
                {isDownloading && (
                  <div style={{ position: "relative" }}>
                    <div
                      className="d-flex
                                        align-items-center 
                                        "
                      style={
                        {
                          // position: "absolute",
                        }
                      }
                    >
                      <FontAwesomeIcon className="mr-2" icon={faDownload} />
                      Downloading...!
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {viewdata && (
            <div className="row m-0">
              <div className=" p-0 col-lg-10 offset-lg-1">
                <h6>Invoice List</h6>
                <div
                  style={{
                    width: "100%",
                    overflowX: "scroll",
                  }}
                >
                  <Table striped>
                    <thead className="threAd">
                      <tr className="threAd_tr">
                        <th>Invoice Date</th>
                        <th style={{ textAlign: "end" }}>Invoice Number</th>
                        <th style={{ textAlign: "center" }}>Download</th>
                      </tr>
                    </thead>

                    {showInvoiceData.map((elm, index) => {
                      return (
                        <tbody className="tboDY" key={index}>
                          <tr>
                            <td>{elm.date}</td>
                            <td style={{ textAlign: "end" }}>{elm.billNo}</td>
                            <td className="BTNs" style={{ minWidth: "400px" }}>
                              <button
                                className="csvBTN"
                                onClick={() => {
                                  getURLCSV(elm, "csv");
                                }}
                              >
                                csv
                              </button>
                              {/* <button className="pdfBTN" onClick={() => { getURLCSV(elm, "pdf") }}  >
                                                                PDF</button> */}
                              <button
                                className="exclBTN"
                                onClick={() => {
                                  getURLCSV(elm, "xlsx");
                                }}
                              >
                                Excel
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </Table>
                </div>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <LoaderTri />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomerFooter />
    </>
  );
}
