import React, { useState } from "react";
import IconTeam from "../../assets/images/Icon_team.png";
import "bootstrap/dist/css/bootstrap.min.css";

import img from "../../Images/slider/aboutus.JPG";
import Slider from "../Home/components/Slider";
import InfoSlider from "../Home/components/InfoSlider";

const AboutUs = () => {
  console.log("About Us");
  const [allImages, setAllImages] = useState([img]);

  const infoSliderData = [{
    contact: [
      "CONSTITUTION : Partnership Firm",
      "CONTACT PERSONS : ",
      "D.S. Guddodgi   Cell  : +91 9980100999 , ",
      "Sangamesh Guddodgi : +91 9845015151",
      "DRUG LICENCE NOS. : ",
      "KA-BJ1-172379,  KA-BJ1-172380,  KA-BJ1-172381",
      "GST No. :  29AABFG3008P1Z8",
      "PAN : AABFG3008P",
      "BANK : Syndicate Bank - Main Branch , Vijayapura ",
      "Transporter : VRL Logistics ",
    ],
  }];

  return (
    <div className="company-info text-center">
      <div className="container">
        <h1 className="heading">COMPANY PROFILE</h1>

        <img className="team-icon" src={IconTeam} alt="line" />

        <h2 className="heading">M/S. GUDDODGI PHARMACEUTICAL DISTRIBUTORS</h2>

        <div className="info-carousel py-5">
          <div className="row w-100 pb-5">
            <div className="col-sm-6">
              <InfoSlider sliderData={infoSliderData} />
            </div>

            <div className="col-sm-6">
              <Slider allImages={allImages} height={350} />
            </div>
          </div>
        </div>

        <div className="pt-5">
          <table
            className="dark-table text-left"
            width="100%"
            border="1"
            cellSpacing="0"
            cellPadding="0"
          >
            <tbody>
              <tr>
                <td height="30" colSpan="3">
                  <div align="center" className="red_title">
                    Our Group of Firms:&nbsp; Independently Handled
                  </div>
                </td>
              </tr>
              <tr>
                <td width="59%" height="30" className="red">
                  Name of the Firm
                </td>
                <td width="18%" className="red">
                  Established on
                </td>
                <td width="23%" className="red">
                  Phone Nos.
                </td>
              </tr>

              <tr>
                <td className="Content_text">
                  M/s. S. Guddodgi &amp; Company ( Cotton Merchants &amp;
                  Commission Agent APMC,Vijayapura)
                </td>
                <td className="Content_text">1976</td>
                <td className="Content_text">08352-250781</td>
              </tr>
              <tr>
                <td className="Content_text">
                  M/s. Guddodgi Agencies&nbsp; ( FMCG Wholesale Dealer )
                </td>
                <td className="Content_text">2009</td>
                <td className="Content_text">
                  08352<span className="Content_text">-222222</span>
                </td>
              </tr>
              <tr>
                <td className="Content_text">&nbsp;</td>
                <td className="Content_text">&nbsp;</td>
                <td className="Content_text">&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <p className="text-left">
            As above said we have an independend FMCG firm in our group ie. M/s
            Guddodgi Agencies, Vijayapura, Presently stockists for
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
