import React from "react";
import { useState } from "react";
import LoaderTri from "../../components/Loader/LoaderTri";
import { Image } from "antd";
import { Company } from "../../assets/Data";
import Slider from "../Home/components/Slider";
import MarqueeLComList from "../Home/components/MarqueeLComList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import img1 from "../../Images/slider/1.JPG";
import img2 from "../../Images/slider/2.JPG";
import img3 from "../../Images/slider/3.JPG";
import img4 from "../../Images/slider/3.JPG";
import img5 from "../../Images/slider/5.JPG";
import img6 from "../../Images/slider/6.JPG";
import img7 from "../../Images/slider/7.JPG";
import img8 from "../../Images/slider/8.JPG";
import img9 from "../../Images/slider/9.JPG";
import img10 from "../../Images/slider/10.JPG";
import img11 from "../../Images/slider/11.JPG";
import img12 from "../../Images/slider/12.JPG";
import img13 from "../../Images/slider/13.JPG";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allImages, setAllImages] = useState([
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
  ]);

  return (
    <div className="pt-3 pb-0">
      <div className="row w-100 no-background">
        <div className="col-md-9">
          <Slider allImages={allImages} height={500} />
        </div>
        <div className="col-md-3 pt-4">
          <MarqueeLComList />
        </div>
      </div>

      <div className="container d-flex justify-content-center py-5">
        <div className="row d-flex w-100">
          <div className="col-md-4 col-sm-12">
            <div className="card-list contact-info w-100 h-100">
              <div className="card-icon">
                <FontAwesomeIcon icon={faLocationDot} />
              </div>

              <p
                className="m-0 py-5 px-4"
                dangerouslySetInnerHTML={{ __html: Company[0].address[1] }}
              ></p>
            </div>
          </div>

          <div className="col-md-4 col-sm-12">
            <div className="card-list contact mx-4 w-100 h-100">
              <div className="card-icon">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div className="py-5 px-4">
                {Company[0].phone.primary.length > 0 &&
                  Company[0].phone.primary.map((item, index) => (
                    <p className="m-0" key={index}>
                      {item.label} : {item.number}
                    </p>
                  ))}

                {Company[0].phone.alternate.length > 0 &&
                  Company[0].phone.alternate.map((item, index) => (
                    <p className="m-0" key={index}>
                      {item.label} : {item.number}
                    </p>
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12">
            <div className="card-list contact mx-4 w-100 h-100">
              <div className="card-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              {Company[0].email != "" && (
                <p className="m-0 py-5 px-4">
                  Email: {Company[0].email}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7637.950454968286!2d75.710792!3d16.827585!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc6ff811f8404c7%3A0xcbd9b51846137ba9!2sGUDDODGI%20PHARMACEUTICAL%20DISTRIBUTORS!5e0!3m2!1sen!2sin!4v1700719070281!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
