import React from "react";
import { Carousel } from "antd";

function InfoSlider({ sliderData }) {
  return (
    <div className="info-card p-5">
      {/* <Carousel autoplay autoplaySpeed={2500}> */}
      <div className="info-section text-left px-sm-2">
        {sliderData.map((item, index) =>
          Object.keys(item).map((itemKey, ii) => (
            <div key={ii}>
              {itemKey === "heading" &&
                item[itemKey].map((value, itemindex) => (
                  <h1 key={itemindex} className="mb-3">
                    {value}
                  </h1>
                ))}
              {itemKey === "list" && (
                <ol className="info-slider-list d-block">
                  {item[itemKey].map((value, itemIndex) => (
                    <li key={itemIndex}>{value}</li>
                  ))}
                </ol>
              )}
              {itemKey === "paragraph" &&
                item[itemKey].map((value, index) => <p key={index}>{value}</p>)}
              {itemKey === "contact" &&
                item[itemKey].map((value, index) => (
                  <p key={index} className="mb-0">
                    {value}
                  </p>
                ))}
            </div>
          ))
        )}
        {/* <h1 className="mb-3">Parner's Description</h1>

          <ol className="info-slider-list d-block">
            <li>{"1) D.S Guddodgi"}</li>
            <li>{"2) Sharanbasappa D Guddodgi"}</li>
            <li>{"3) Sangamesh D Guddodgi"}</li>
          </ol>

          <p>
            Presently we have 1200 total divisions of more than 500 companies
            catering the needs of retail chemist brothers mainly in Vijayapura,
            Bagalkot and Belgaum districts.
          </p>

          <p>
            We find our chemist brothers as representatives of GOD in getting
            the blessings from them.
          </p>

          <p>
            We always prepared to make availability of any required medicines of
            company marketing in Karnataka as per the requirements of the
            chemist brothers.
          </p> */}
      </div>
      {/* </Carousel> */}
    </div>
  );
}

export default InfoSlider;
