import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import officeImage from "../../Images/page_3.jpg";

function Services() {
  return (
    <div className="sercvice-component container pt-5">
      <section>
        <div className="row w-100">
          <div className="col-md-8">
            <h3>SERVICES</h3>
            <p>
              We Accept Any Products Purchased From Us
              Non-Moving/Breakage/Expiry At Any Moment And Prepare A Credit Note
              (Value Based) And Adjust In The Immediate Next Invoice
              Automatically.
            </p>

            <p>
              We Handover Stocks To Chemists Every Time, Along With The Invoice
              On Every Purchase At The Same Time, (Instead Of Delivery
              Challans).
            </p>

            <p>
              We Have A Walk-In Cold Room For The Cold Chain Management. We Also
              Provide Instant Payment Receipt Voucher Via Our Handheld
              Electronic Device.
            </p>

            <p>
              Entire Sales Force Connected With Mobile Network To Ease
              TheOperations & Instant Availability To Retailers.
            </p>

            <p>
              Unique Product List & Scheme List Distributed To All Retailers
              Periodically.Bulk SMS Service To The Chemists With New Companies &
              Products. We Still Want To Grow And Wish To Give Services To The
              Principles.
            </p>
          </div>

          <div className="col-md-4">
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/7D59doJKNFM?si=dil03CvQjjgrfXjV"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>

      <section>
        <div className="row w-100">
          <div className="col-12">
            <h3>FACILITIES TO CHEMIST BROTHERS</h3>

            <p>
              We Supplies To About 1000 Chemists By Booking Through The Salesmen
              And Direct Orders From Chemists Via Our Android App In The City As
              Well As Interiors And Dispatches /Deliveries Arranged To Reach
              Their Destination Within 24 Hours.
            </p>

            <p>
              We Transfers Honestly The Free Items And The Product Discount To
              The Retail Trade For Whom It Is Meant For, And Circulates
              Scheme/Bonus Offer List Bi-Monthly To 1000 Chemists.
            </p>

            <p>
              We Supplies 100% Same Batch Number Of The Medicines Supplied And
              Invoiced To The Retailers, By Booking Facility Twice In A Week For
              Some Territories, Daily For Certain Territories And City.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="row w-100">
          <div className="col-12">
            <p>
              We Provide Stock And Sales Statement Whenever Demanded By The
              Concerned Representatives And During Manager’s Visit Also.
            </p>

            <p>
              We Have Kept Open Display Of Updated Daily Sales Of Present Date
              In Comparison With Same Dates Of Past Month And Previous To Past
              Month, To Verify Their Sale And To Make Convenient Of Achievement
              Of The Target Of The Representatives.
            </p>

            <p>
              Provided Two Systems (Computers) To View The Concerned Stock And
              Sales Statements By The Representatives And If Necessary Take A
              Statement To Get Confirmed That The Promotional Activity Has
              Yielded The Fruits, Through The Password Provided To Them.
            </p>

            <p>
              Reps Can Also View The Stock And Sales Statement Online By Logging
              Into <a href="https:www.guddodgipharma.com" target='_blank'>Www.Guddodgipharma.Com</a>
            </p>

            <p>Mostly We Are Not Allowing Any Product To Go Out Of Stock.</p>

            <p>
              Installed The Software Connecting To The Companies For Taking
              Stock And Sales Statement Through AIOCD Pharma Awacs For
              Autobilling System And Import/Export Of Invoices Through Email.
            </p>

            <p>
              We Have Website To Make It Convenience Of Details Of Stock And
              Sales Statement With Purchase And Sales Details, Sitting At The
              Companies Offices And Representatives During Their Tour Also May
              Extract The Details, Which Will Assure Them Holding Of Stocks Of A
              Particular Product.
            </p>
          </div>
        </div>
      </section>

      <section className="no-border">
        <div className="row w-100">
          <div className="col-md-7">
            <h3>WELL EXPERIENCED STAFF</h3>

            <p>
              We Have Each 8000 Sq.Ft. Well Constructed Beautiful Building.
              Cellar – Ground – Mezzanine/1st Floor, Highly Equipped
            </p>
            <ul className="list">
              <li>
                <div>
                  <FontAwesomeIcon icon={faSquareCheck} />
                </div>
                <div>Managers/Supervisors</div>
              </li>
              <li>
                <div>
                  <FontAwesomeIcon icon={faSquareCheck} />
                </div>
                <div>Salesman - Interior Area Bookings</div>
              </li>
              <li>
                <div>
                  <FontAwesomeIcon icon={faSquareCheck} />
                </div>
                <div>Staff For Supply Of Stocks Processing /Checking</div>
              </li>
              <li>
                <div>
                  <FontAwesomeIcon icon={faSquareCheck} />
                </div>
                <div>Staff For Packing And Forwarding</div>
              </li>
              <li>
                <div>
                  <FontAwesomeIcon icon={faSquareCheck} />
                </div>
                <div>Staff For Accounts/Computer Operators</div>
              </li>
              <li>
                <div>
                  <FontAwesomeIcon icon={faSquareCheck} />
                </div>
                <div>
                  Salesman – Local Bookings With 10 Salesman And 10 Delivery
                  Boys To Deliver Stocks To Local Chemists
                </div>
              </li>
            </ul>
          </div>

          <div className="col-md-5">
            <img src={officeImage} alt="office image" className="img-fluid" />
          </div>

          <div className="col-12">
            <p>
              We Deliver Stocks Through Our Own Vehicles, 4 Wheelers Are For
              Service To The Interior Areas And 2 Wheelers For City Area
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services